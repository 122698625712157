import React, { Component } from "react";

import "./Delivery.scss";

import { Icon } from "@iconify/react";
import thumbsUp from "@iconify/icons-fa-solid/thumbs-up";
import flowerAlt from "@iconify/icons-zmdi/flower-alt";

import aboutImage from "../../assets/images/about/about-image.jpg";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";
import { getSettings } from "../../services/Settings";
import { GetFlowersByCategory } from "../../services/Flowers";

class BackPage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    modalLoginActive: false,
    user: null,

    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },

    cart: [],
    mainList: [],
    giftsList: [],
    waifuList: [],
    manList: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {
    let _cart = retrieveData("cart");
    let _user = retrieveData("mfUser");
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    GetFlowersByCategory(0).then((res) => {
      this.setState({ mainList: res.response });
      console.log(res);
    });
    GetFlowersByCategory(1).then((res) => {
      this.setState({ waifuList: res.response });
    });
    GetFlowersByCategory(2).then((res) => {
      this.setState({ manList: res.response });
    });
    GetFlowersByCategory(3).then((res) => {
      this.setState({ giftsList: res.response });
    });
  }

  addToCart(_cart) {
    this.setState({ basketNum: getBasketNum(_cart) });
    storeData("cart", _cart);
  }

  render() {
    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Доставка MATE flowers</title>
          <meta name="description" content="Условия доставки MATE flowers" />
          <meta name="keywords" content="Доставка букетов Королев, купить цветы Королев" />
        </Helmet>
        <div className="page-wrapper">
          {/* delivery header */}
          <section className="section about-header">
            <UiHeader
              callBack={(value) => { }}
              page="delivery"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>
          {/* delivery title */}
          <section className="section about-title about-title-bordered">
            <UiPageTitle
              backImg={require("../../assets/images/header/photo-2019-09-11-13-26-12_1x.jpg")}
              pageLink="/delivery"
              pageName="Возврат товара"
              title="Возврат товара"
            />
          </section>
          {/* delivery catalog */}
          <section className="section about">
            <div className="wrapper">
              <div className="delivery-wrap">

                <div className="delivery-info-text">
                  <p>
                    Мы очень трепетно относится к своим клиентам. В связи с этим, наша компания тщательно отбирает для вас самые свежие и красивые цветы. Наши флористы, которые занимаются составлением букетов для Вас, отличаются высоким уровнем профессионализма и надежности.
                  </p>
                  <p>
                    Наши менеджеры рассмотрят любые Ваши претензии по сервису или качеству товара. Свяжитесь с нашими менеджерами любым удобным способом и пришлите фотографию цветка, с указанием номера заказа и описанием претензии.
                  </p>
                  <p>
                    Мы рассмотрим Вашу претензию в течение 12 часов после получения письма и свяжемся с Вами. Если качество цветка в букете или работа флориста по его составлению не соответствуют заявленному на сайте, Компания готова предоставить Заказчику аналогичный товар и за счет собственных средств осуществить доставку товара Заказчику. Либо вернуть денежные средства поступившие от Заказчика тем же способом, которым была осуществлена оплата.
                  </p>
                </div>
              </div>
            </div>
          </section>
          {/* delivery footer */}
          <section className="section classes-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />
      </div>
    );
  }
}

export default BackPage;
