import React, { Component } from "react";

import { Icon } from "@iconify/react";
import alertCircle from "@iconify/icons-zmdi/alert-circle";
import "./Basket.scss";
import UiAddGiftSlider from "../../components/sliders/UiAddGiftSlider";
import UiBasketCard from "../../components/cards/UiBasketCard";
import UiBtnPink from "../../components/buttons/UiBtnPink";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalGift from "../../components/modals/UiModalGift";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiModalRules from "../../components/modals/UiModalRules";
import UiModalCatalog from "../../components/modals/UiModalCatalog";
import UiTextInputButton from "../../components/forms/UiTextInputButton";
import { Helmet } from "react-helmet";
import { loadUser } from "../../services/Auth";
import { storeData, retrieveData } from "../../services/Storage";
import { getSettings } from "../../services/Settings";
import { GetCartRecommendation, GetFlowersById } from "../../services/Flowers";
import { getEnvOfStorage } from "../../services/EnvContext";
import {
  calculatePurchase, CART,
  generateOrderIdForCart,
  generateOrderIdForNewCart,
  getBalance,
  getOrderIdForCart, setOrder
} from "../../services/Loyalty";
import UiTotalBonuses from "../../components/loyalty/UiTotalBonuses";
import { connect } from "react-redux";
import { setPromoCode, setTotalBonuses, setTotalDiscount } from "../../Store/Slices/LoyaltySlice";


class BasketPage extends Component {
  state = {
    showTopBtn: false,
    scrollHeader: false,
    mobileBasket: false,
    basketNum: 0,
    user: null,
    totalDiscount: 0,
    appliedBonuses: 0,

    cart: [],
    giftsList: [],
    giftTypesList: [],
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },
    addressesHistory: [
      { value: 0, label: "Королев, ул Вавилова 22", distance: 0, type: "old" },
      { value: 1, label: "г Королев, ул Ленина 13", distance: 11, type: "old" },
      { value: 2, label: "Добавить новый адрес", distance: 0, type: "new" },
    ],
    settings: {
      settings_price_giftbox: 300,
      settings_sale: 0,
      settings_percent_to_bonus: 0,
      settings_max_percent_discount: 30
    },
    selectedDeliveryTime: "",
    selectedDeliveryPrice: 0,
    selectedDeliveryValue: 0,

    deliveries: [
      { value: 0, label: "Доставка до 5 км за МКАД", price: 600 },
      { value: 1, label: "Доставка от 5 до 10 км за МКАД", price: 800 },
      { value: 2, label: "Доставка от 10 до 15 км за МКАД", price: 1000 },
      {
        value: 3,
        label: "С вами свяжется наш менеджер для уточнения заказа",
        price: 0,
      },
      { value: 4, label: "", price: 0 },
    ],

    /* modals */
    modalGiftActive: false,
    modalCatalogActive: false,
    modalDeliveryActive: false,
    modalLoginActive: false,
    modalRulesActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });

    if (document.getElementById("page")) {
      let windowWidth = window.innerWidth;
      console.log(windowWidth);
      this.setState({ mobileBasket: windowWidth < 991 });
    }
  }

  _scrollToTargetAdjusted(target) {
    var element = document.getElementById(target);
    var headerOffset = -82;
    if (window.innerWidth < 991) headerOffset = -82;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  _changeCount(val, state) {
    if (state) {
      let arr = this.state.cart;
      arr.map((item, index) => {
        if (item.id == val) item.count++;
      });
      this.setState({ cart: arr, basketNum: arr.length });
      storeData("cart", arr);
    } else {
      let arr = this.state.cart;
      let kk = -1;
      arr.map((item, index) => {
        if (item.id == val) {
          item.count--;
          if (item.count == 0) kk = index;
        }
      });
      if (kk > -1) {
        arr.splice(kk, 1);
      }
      this.setState({ cart: arr, basketNum: arr.length });
      storeData("cart", arr);
    }
  }

  /**
   * Change function
   * @param {string} _key 
   * @param {int} _index 
   * @param {string} _val 
   */
  _change(_key, _index, _val) {
    let arr = this.state.cart;
    arr[_index][_key] = _val;
    this.setState({ cart: arr });
    storeData("cart", arr);
  }

  _getTotalBasket(excludeSale = false) {
    if (this.state.settings) {
      let arr = this.state.cart;
      let price = 0;
      let giftPrice = 0;
       
      arr.map((item, index) => {
        //скидок на подарок нет
        if (item.category != 0 ) {
          giftPrice =
            giftPrice +
            parseFloat(item.price * item.count) +
            parseFloat(
              item.giftbox == 1 ? this.state.settings.settings_price_giftbox : 0
            );
        } else {
          price =
            price +
            parseFloat(item.price * item.count) +
            parseFloat(
              item.giftbox == 1 ? this.state.settings.settings_price_giftbox : 0
            );
        }

      });
      if (!excludeSale) {
        console.log( price, giftPrice, this.state.totalDiscount , (price - this.state.totalDiscount   < 0 ? 0 : price - this.state.totalDiscount ) + giftPrice + parseFloat(this.state.selectedDeliveryPrice) - this.state.appliedBonuses )
        //Пересчитает стоимость заказа с учетом бонусов и скидки
        if ((price + parseFloat(this.state.selectedDeliveryPrice) - this.state.appliedBonuses - this.state.totalDiscount ) > 0) {
          return (price - this.state.totalDiscount   < 0 ? 0 : price - this.state.totalDiscount ) + giftPrice + parseFloat(this.state.selectedDeliveryPrice) - this.state.appliedBonuses;
        }
      }

      return price + giftPrice + parseFloat(this.state.selectedDeliveryPrice);
    }
  }

  _removeItem(_index) {
    let arr = this.state.cart;
    arr.splice(_index, 1);
    storeData("cart", arr);
    this.setState({ cart: arr });
  }

  hasInCart(id) {
    let fl = false;
    this.state.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  _toCart(item) {
    let arr = this.state.cart;
    let _item = item;
    _item.count = 1;
    _item.color = 0;
    _item.giftbox = false;

    if (this.hasInCart(_item.id)) {
      _item.count = _item.count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }

  addToCart(_cart) {
    this.setState({ basketNum: _cart.length, cart: _cart, infoActive: true });
    storeData("cart", _cart);
  }

  _convertImages(_img) {
    let arr = [];
    let imgSrc = null;
    if (_img != null) arr = _img.split(",");
    if (arr.length > 0)
      imgSrc = getEnvOfStorage()?.uploadsUrl + arr[0].replace(".jpg", "-min.jpg");
    return imgSrc;
  }

  async checkActualPrice() {
    let _cart = this.state.cart;
    const list = await Promise.all(this.state.cart.map(item => GetFlowersById(item.id)));

    let saveIdArr = [];
    list.map((item) => {
      if (item.response.length > 0) {
        if (item.response[0].id) {
          saveIdArr.push(item.response[0].id);
          _cart.map((itemCart) => {
            if (itemCart.id == item.response[0].id) {
              if (itemCart.price != item.response[0].price) {
                itemCart.price = item.response[0].price;
              }
            }
          })
        }
      }
    })


    var i = _cart.length
    while (i--) {
      let f = false;
      saveIdArr.map((item) => {
        if (_cart[i].id == item) {
          f = true;
        }
      })
      if (!f) _cart.splice(i, 1);
    }


    storeData("cart", _cart);

    if (_cart.length == 0) {
      storeData("cart", []);
     // this.props.history.push("/")
    }

    return await new Promise((resolve) =>
      this.setState({
        cart: _cart,
      }, resolve)
    );

  }

  _load() {
    let _cart = retrieveData("cart");
    generateOrderIdForCart();
    let _user = loadUser();
    if (_cart) {
      this.setState({ cart: _cart }, () => this.checkActualPrice());
      this.setState({ basketNum: _cart.length });
    }
    if (_user) {
      this.setState({ user: _user });
      this.recalculateBonuses(_cart, _user);
    }

    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    GetCartRecommendation().then((res) => {
      this.setState({ giftsList: res.response });
    });

    
  }

  _getTotalBasketSales(cart) {
    if (this.state.settings) {
      let arr =  cart;
      let giftPrice = 0;
      arr.map((item, index) => {
        if (item.category == 0 || item.category == 8 || item.category == 9 ) {
          console.log(item)
          giftPrice =
            giftPrice +
            parseFloat(item.price * item.count) +
            parseFloat(
              item.giftbox == 1 ? this.state.settings.settings_price_giftbox : 0
            );
        } 

      }); 
 
      return giftPrice;
    }
  }

  recalculateBonuses(cart, user) {
    getBalance(user).then((data) => {
      if (data?.client) {
        this.props.setTotalBonuses(data?.client?.bonuses);
      }
    });
  }

  /**
   * Получить скидку по промокоду
   * @param {any} cart
   * @param {any} user
   * @param {string} promoCode
   */
  sendPromo(cart, user, promoCode) {
    if (  (cart?.length > 0) && (promoCode?.length > 0)) {
      setOrder(cart, generateOrderIdForCart(), 0, user, promoCode)
        .then((data) => {

          let discount = data?.summary?.totalDiscount | 0;
          //let maxSumm = this._getTotalBasket(true) * ((this.state.settings?.settings_max_percent_discount | 15) / 100);
          let maxSumm =   this._getTotalBasketSales(cart) * ((this.state.settings?.settings_max_percent_discount | 15) / 100)  ;

  
          this.props.setTotalDiscount(discount > maxSumm ? maxSumm : discount);
          this.setState({ totalDiscount: discount > maxSumm ? maxSumm : discount });
        })
        .catch(err => console.error(err));
    }
  }

    /**
   * Получить скидку по промокоду
   * @param {any} cart
   * @param {string} promoCode
   */
     checkPromo(cart,  promoCode) {
      if (  (cart?.length > 0) && (promoCode?.length > 0)) {
        calculatePurchase(cart,  promoCode)
          .then((data) => {
  
            let discount = data?.summary?.totalDiscount | 0;
            //let maxSumm = this._getTotalBasket(true) * ((this.state.settings?.settings_max_percent_discount | 15) / 100);
            let maxSumm =   this._getTotalBasketSales(cart) * ((this.state.settings?.settings_max_percent_discount | 15) / 100)  ;
  
    
            this.props.setTotalDiscount(discount > maxSumm ? maxSumm : discount);
            this.setState({ totalDiscount: discount > maxSumm ? maxSumm : discount });
          })
          .catch(err => console.error(err));
      }
    }


  

  render() {
    let basketList = this.state.cart.map((item, index) => {
      return (
        <UiBasketCard
          key={index}
          image={this._convertImages(item.imgs)}
          giftBox={item.giftbox}
          count={item.count}
          category={item.category}
          price={item.price * item.count}
          title={item.title}
          url={item.title_url}
          category={item.category}
          onPlus={() => {
            this._changeCount(item.id, true);
          }}
          onMinus={() => {
            this._changeCount(item.id, false);
          }}
          onDelete={() => {
            this._removeItem(index);
          }}
          onShow={() => this.setState({
            modalCatalogActive: true,
            selectedItem: item,
          })}
          onChangeGiftBox={(val) =>
            this._change("giftbox", index, val == 1 ? true : false)
          }
        />
      );
    });


    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Корзина MATE flowers</title>
          <meta name="description" content="Корзина MATE flowers" />
          <meta name="keywords" content="Доставка букетов Королев, купить цветы Королев" />
        </Helmet>
        <div className="page-wrapper">
          {/* basket header */}
          <section className="section basket-header">
            <UiHeader
              callBack={(value) => { }}
              page="basket"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>

          {/* basket content */}
          <section className="section basket-content">
            <div className="wrapper">
              <div className="basket-content-inner">
                {this.state.cart.length > 0 ? (
                  <div className="basket-wrap">
                    <div className="basket-order-info">
                      <h2>
                        <span>Ваш заказ</span>{" "}
                        <button
                          onClick={() =>
                            this.setState({ modalRulesActive: true })
                          }
                        >
                          <Icon icon={alertCircle} />
                        </button>
                      </h2>
                      <div className="basket-order">
                        {basketList}
                        <UiBasketCard
                          price="0"
                          title="Подарочный набор"
                          textInfo="К текущему заказу бесплатно прилагается открытка
                          MATE flowers, подкормка для цветов, инструкция к
                          букету, упаковка и аквабокс."
                          type="free"
                        />
                        {this.state.cart.length > 0 ? <p></p> : null}
                      </div>
                    </div>
                    {this.state.mobileBasket ? (
                      <div className="basket-add-more">
                        <div className="add-more">
                          {this.state.cart.length > 0 ? (
                            <h3>К вашему заказу также подходят:</h3>
                          ) : (
                            <h3>
                              Не забудьте заказать подходящий подарок после
                              выбора букета:
                            </h3>
                          )}
                          <UiAddGiftSlider
                            giftsList={this.state.giftsList}
                            showClick={(item) =>
                              this.setState({
                                modalCatalogActive: true,
                                selectedItem: item,
                              })
                            }
                            addClick={(item) =>
                              this.addToCart(this._toCart(item))
                            }
                          />
                          <UiBtnPink btnText="Смотреть все" href="/gifts" />
                        </div>
                      </div>
                    ) : null}

                    <div className="basket-order-promo">
                      <h3>Промокод</h3>
                      <UiTextInputButton
                        placeholder="Введите промокод"
                        btnText="Применить"
                        callBack={(val) => this.props.setPromoCode(val)}
                        onClick={() => { this.checkPromo(retrieveData(CART),   this.props.promoCode) }}
                      />
                      <p>Размер скидки: <b>{this.state.totalDiscount } ₽ (не более {((this.state.settings?.settings_max_percent_discount | 15))}% суммы букета)</b></p>
                      <UiBtnPink
                        btnText="Оформить заказ"
                        btnNum={this._getTotalBasket()}
                        priceBtn
                        btnBig
                        onClick={() => {
                          if (this.state.user) {
                            this.checkActualPrice().then((val) => {
                              if (this.state.cart.length > 0) this.props.history.push("/order"); else this.props.history.push("/");
                            })
                          } else this.setState({ modalLoginActive: true });
                        }}
                      />
                      {/*Виджет общего числа доступных бонусов*/}
                      <UiTotalBonuses />

                      <div className="order-info">
                        <p>Мы обрабатываем заказы с 9.00 до 21.00. Заказы, созданные после 19.30 переносятся на следующий день. </p>
                        <p>
                        При оформлении заказа в ночное время, букет может быть доставлен не ранее 11:00 следующего дня.
                        </p>
                        <p>
                        При срочной доставке мы постараемся доставить букет, как можно скорее, точное время зависит от загруженности магазина и дорог 🌿
                        </p>
                      </div>
                    </div>

                    {!this.state.mobileBasket ? (
                      <div className="basket-add-more">
                        <div className="add-more">
                          {this.state.cart.length > 0 ? (
                            <h3>К вашему заказу также подходят:</h3>
                          ) : (
                            <h3>
                              Не забудьте заказать подходящий подарок после
                              выбора букета:
                            </h3>
                          )}
                          <UiAddGiftSlider
                            giftsList={this.state.giftsList}
                            showClick={(item) =>
                              this.setState({
                                modalCatalogActive: true,
                                selectedItem: item,
                              })
                            }
                            addClick={(item) =>
                              this.addToCart(this._toCart(item))
                            }
                          />
                          <UiBtnPink btnText="Смотреть все" href="/gifts" />
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div className="basket-empty">
                    <img
                      src={require("../../assets/images/basket/shopping-basket.svg")}
                    />
                    <h3>Ваша корзина пуста</h3>
                    <p>Для заказа букета перейдите на страницу «Главная»</p>
                  </div>
                )}
              </div>
            </div>
          </section>

          {/* basket footer */}
          <section className="section basket-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        {/* modals */}
        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.props.history.push("/order");
          }}
        />

        <UiModalGift
          imageList={this.state.giftTypesList}
          modalOpen={this.state.modalGiftActive}
          modalClose={() => this.setState({ modalGiftActive: false })}
        />

        <UiModalRules
          modalOpen={this.state.modalRulesActive}
          modalClose={() => this.setState({ modalRulesActive: false })}
        />

        <UiModalCatalog
          modalOpen={this.state.modalCatalogActive}
          modalClose={() => this.setState({ modalCatalogActive: false })}
          imageList={this.state.selectedItem.imgs}
          item={this.state.selectedItem}
          title={this.state.selectedItem.title}
          filter="1"
          description={this.state.selectedItem.description}
          composition={this.state.selectedItem.composition}
          giftsList={this.state.giftsList}
          colors={
            this.state.selectedItem.modifications != null
              ? this.state.selectedItem.modifications
                .split(",")
                .filter((elem, index, self) => {
                  return index === self.indexOf(elem);
                })
              : []
          }
          price={this.state.selectedItem.price}
          cart={this.state.cart}
          settings={this.state.settings}
          onClick={(cart) => {
            this.addToCart(this._toCart(cart));
            this.setState({ modalCatalogActive: false });
          }}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTotalBonuses: (value) => dispatch(setTotalBonuses(value)),
    setPromoCode: (value) => dispatch(setPromoCode(value)),
    setTotalDiscount: (value) => dispatch(setTotalDiscount(value)),
  }
};

function mapStateToProps(state) {
  const { loyalty } = state;

  return { promoCode: loyalty.promoCode, totalDiscount: loyalty.totalDiscount }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketPage);
