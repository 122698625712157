import React, { useEffect, useState } from "react";

import "./UiMap.scss";

const UiMapAddress = (props) => {
  const json =  {"type":"FeatureCollection","metadata":{"name":"mate","creator":"Yandex Map Constructor"},"features":[{"type":"Feature","id":0,"geometry":{"type":"Polygon","coordinates":[[[37.405291107925656,55.69751254027933],[37.4331798575516,55.662407917119886],[37.45969531616205,55.63892475800462],[37.49334094604485,55.60959111405624],[37.51325366577141,55.59442999879288],[37.59771106323236,55.57459504460255],[37.65195605834954,55.571871814267034],[37.687661624755805,55.575762085252094],[37.73023364624014,55.592680260636655],[37.7837919958495,55.617946648668976],[37.834113536662365,55.65019786505358],[37.83995002347877,55.658738799866235],[37.831710277385035,55.68745366846508],[37.829650340861576,55.698894912181686],[37.838576732463146,55.71382164733274],[37.843039928263934,55.77753503296386],[37.83754676420142,55.82240082696482],[37.7891382559006,55.850415591228476],[37.726310191935646,55.88265576176632],[37.70159095365438,55.894232495214716],[37.646659313029396,55.89654742576664],[37.59447425443565,55.908505690790356],[37.57318824369345,55.91159109370418],[37.480491100138764,55.88574322976519],[37.445472179240284,55.88188385620098],[37.40976661283404,55.870110386675584],[37.39740699369341,55.85852642708045],[37.391913829630916,55.847904749659435],[37.39363044340046,55.82568636533217],[37.384704051798884,55.8011347240865],[37.370971141642634,55.787595673353366],[37.36939648373389,55.74692507509003],[37.37694958431984,55.7312361330751],[37.38827923519874,55.709726183329856],[37.405291107925656,55.69751254027933]]]},"properties":{"fill":"#ed4543","fill-opacity":0,"stroke":"#ed4543","stroke-width":"2","stroke-opacity":0.5}}]};

  const [deliveryZones, setDeliveryZones] = useState([]);
  const [deliveryPoint, setDeliveryPoint] = useState(null);
  const [mainMap, stMainMap] = useState(null);

  useEffect(() => {
    window.ymaps.ready(() => {
      var placemark = null;
      var myMap = new window.ymaps.Map("mapAddress", {
          center: [55.914060, 37.841469],
          controls: [],
          zoom: 13,
        }),
        deliveryPoint = new window.ymaps.GeoObject(
          {
            geometry: { type: "Point" },
          },
          {
            preset: "islands#blackDotIcon",
            draggable: true,
            iconCaptionMaxWidth: "215",
          }
        );

      deliveryPoint.events.add("dragend", (e) => {
        var target = e.get("target");
        target.properties.set(
          "balloonContentBody",
          target.geometry.getCoordinates()
        );
        myMap.geoObjects.add(placemark);

        window.ymaps
          .geocode(placemark.geometry.getCoordinates(), {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
      });

      myMap.geoObjects.add(deliveryPoint);
      setDeliveryPoint(deliveryPoint);

      stMainMap(myMap);

      myMap.events.add("click", (e) => {

      
        var position = e.get("coords");
        deliveryPoint.geometry.setCoordinates(position);
        window.ymaps
          .geocode(position, {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });
      });

      // Сравним положение, вычисленное по ip пользователя и
      // положение, вычисленное средствами браузера.
      window.ymaps.geolocation
        .get({
          provider: "browser",
          mapStateAutoApply: true,
        }).then((result) => {
          let firstGeoObject = result.geoObjects.get(0),
            coords = firstGeoObject.geometry.getCoordinates();
          if( props.address == null ||  props.address == ""){
            deliveryPoint.geometry.setCoordinates(coords);
            myMap.setCenter(coords);
            stMainMap(myMap);
          }  else {
            var geocoder = new window.ymaps.geocode(props.address).then((res) => {
              var firstGeoObject = res.geoObjects.get(0),
                coords = firstGeoObject.geometry.getCoordinates();
                deliveryPoint.geometry.setCoordinates(coords);
                myMap.setCenter(coords);
                stMainMap(myMap);
            });
          }
        });

      if(document.getElementById("suggest-input")){
        var suggestView = new window.ymaps.SuggestView("suggest-input", {
          provider: {
            suggest: function (request, options) {
              return window.ymaps.suggest("Королев , " + request);
            },
          },
        });

        suggestView.events.add("select", (e) => {
          var addr = e.originalEvent.item.value;
          props.onChangeAddress(addr);
        });

        document.getElementById("suggest-input").onblur = () => {
          //console.log("document.getElementById ", document.getElementById("suggest").value );
        };
  
      }
      

    

      
      // Добавляем зоны на карту.
      var _deliveryZones = window.ymaps.geoQuery(json).addToMap(myMap);
   
      // Задаём цвет и контент балунов полигонов.
      _deliveryZones.each(function (obj) {
        obj.events.add("click", (e) => { 
          var position = e.get("coords");
          deliveryPoint.geometry.setCoordinates(position);
  
          window.ymaps
          .geocode(position, {
            results: 1,
          })
          .then((res) => {
            var newContent = res.geoObjects.get(0)
              ? res.geoObjects.get(0).properties.get("text")
              : "Не удалось определить адрес.";
            props.onChangeAddress(newContent);
            highlightResult(
              deliveryZones,
              deliveryPoint,
              res.geoObjects.get(0)
            );
          });

        });

        obj.options.set({
          fillColor: obj.properties.get("fill"),
          fillOpacity: 0.01,
          strokeColor: obj.properties.get("stroke"),
          strokeWidth: obj.properties.get("stroke-width"),
          strokeOpacity: 0.1,
        });
        obj.properties.set("balloonContent", obj.properties.get("description"));
      });

      setDeliveryZones(_deliveryZones);

      // При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку.
      deliveryPoint.events.add("dragstart", function () {
        deliveryPoint.properties.set({ iconCaption: "", balloonContent: "" });
        deliveryPoint.options.set("iconColor", "black");
      });

      // По окончании перемещения метки вызываем функцию выделения зоны доставки.
      deliveryPoint.events.add("dragend", () => {
        console.log(deliveryZones, deliveryPoint, deliveryPoint)
        highlightResult(deliveryZones, deliveryPoint, deliveryPoint);
      });
    });
  }, []);

  useEffect(() => {
    if (props.address) {
      var geocoder = new window.ymaps.geocode(props.address).then((res) => {
        var firstGeoObject = res.geoObjects.get(0),
          coords = firstGeoObject.geometry.getCoordinates();
        deliveryPoint.geometry.setCoordinates(coords);
        mainMap.setCenter(coords); 
        highlightResult(deliveryZones, deliveryPoint, firstGeoObject);
      
      });
    }
  }, [props.address]);

  function highlightResult(deliveryZones, deliveryPoint, obj) {
  
    var coords = obj.geometry.getCoordinates(), 
      polygon = deliveryZones.searchContaining(coords).get(0); 
    if (polygon) {
      console.log("in zone", polygon.properties.get("description"));
 
      deliveryPoint.options.set("iconColor", polygon.properties.get("fill")); 
      props.onChange(0);
    } else {
      console.log("out zone");
 
      deliveryPoint.options.set("iconColor", "black");
 
      //getRoute(coords, mainMap)

      var multiRoute = new window.ymaps.multiRouter.MultiRoute({ 
        referencePoints: [
            "Королёв, Калининградская, 12",
            `${props.address}`
        ], 
        params: { results: 1 } 
      }, {});

      multiRoute.model.events.add('requestsuccess', function() {
        // Получение ссылки на активный маршрут.
        var activeRoute = multiRoute.getActiveRoute();
        // Вывод информации о маршруте.
        console.log( activeRoute.properties.get("distance") ); 
        props.onChange( Math.round(activeRoute.properties.get("distance").value/1000 - 20) );
    });



    }
  }

  function getRoute(coords, myMap){
    window.ymaps.route([[ 55.914060, 37.841469 ], coords ]).then(
      (res) => {
        // Объединим в выборку все сегменты маршрута.
        var pathsObjects = window.ymaps.geoQuery(res.getPaths()),
            edges = [];
            
        // Переберем все сегменты и разобьем их на отрезки.
        pathsObjects.each(function (path) {
            var coordinates = path.geometry.getCoordinates();
            for (var i = 1, l = coordinates.length; i < l; i++) {
                edges.push({
                    type: 'LineString',
                    coordinates: [coordinates[i], coordinates[i - 1]]
                });
            }
        });
        
        // Создадим новую выборку, содержащую:
        // - отрезки, описываюшие маршрут;
        // - начальную и конечную точки;
        // - промежуточные точки.
        var routeObjects = window.ymaps.geoQuery(edges)
                .add(res.getWayPoints())
                .add(res.getViaPoints())
                .setOptions('strokeWidth', 3)
                .addToMap(myMap),
            // Найдем все объекты, попадающие внутрь МКАД.
            objectsInMoscow = routeObjects.searchInside(deliveryZones),
            // Найдем объекты, пересекающие МКАД.
            boundaryObjects = routeObjects.searchIntersect(deliveryZones);
        // Раскрасим в разные цвета объекты внутри, снаружи и пересекающие МКАД.
        boundaryObjects.setOptions({
            strokeColor: '#06ff00',
            preset: 'islands#greenIcon'
        });
        objectsInMoscow.setOptions({
            strokeColor: '#ff0005',
            preset: 'islands#redIcon'
        });
        // Объекты за пределами МКАД получим исключением полученных выборок из
        // исходной.
        routeObjects.remove(objectsInMoscow).remove(boundaryObjects).setOptions({
            strokeColor: '#0010ff',
            preset: 'islands#blueIcon'
        });

        console.log("Длина: " , routeObjects ); 
        //props.onChange( activeRoute.properties.get("distance").text );

    }
);

  }

  return <div className="map-address" id="mapAddress"></div>;
};
export default UiMapAddress;
