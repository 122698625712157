import React, { Component } from "react";

import "./Classes.scss";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";
import UiCatalogCard from "../../components/cards/UiCatalogCard";
import UiHeader from "../../components/header/UiHeader";
import UiFooter from "../../components/footer/UiFooter";
import UiModalCatalog from "../../components/modals/UiModalCatalog";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiPageTitle from "../../components/header/UiPageTitle";

import { Helmet } from "react-helmet";

import { storeData, retrieveData, getBasketNum } from "../../services/Storage";

class AgreementPage extends Component {
    state = {
        showTopBtn: false,
        scrollHeader: false,
        basketNum: 0,
        modalCatalogActive: false,
        modalLoginActive: false,
        user: null,

        settings: {},
        selectedItem: {
            title: null,
            description: null,
            composition: null,
            price: null,
            modifications: null,
        },

        cart: [],
        classesList: [],
        giftsList: [],
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._load();

        window.addEventListener("scroll", (event) => {
            let scroll = document.documentElement.scrollTop;
            this.setState({ topOffset: scroll });
            if (this.state.topOffset > 170) {
                this.setState({ scrollHeader: true });
            } else {
                this.setState({ scrollHeader: false });
            }
            if (this.state.topOffset > 500) {
                this.setState({ showTopBtn: true });
            } else {
                this.setState({ showTopBtn: false });
            }
        });
    }

    _load() {
        let _cart = retrieveData("cart");
        let _user = retrieveData("mfUser");
        if (_cart) {
            this.setState({ cart: _cart });
            this.setState({ basketNum: getBasketNum(_cart) });
        }
        if (_user) {
            this.setState({ user: _user });
        }

    }


    render() {

        return (
            <div ref={this.divPage} id="page">
                <Helmet>
                    <title>Пользовательское соглашение MATE flowers</title>
                    <meta name="description" content="Политика в отношении обработки персональных данных" />
                    <meta name="keywords" content="цветочная подписка Королев, доставка цветов Королев" />
                </Helmet>
                <div id="wrapper" className="page-wrapper">
                    {/* gifts header */}
                    <section className="section classes-header">
                        <UiHeader
                            callBack={(value) => { }}
                            page="subscribe"
                            scrollHeader={this.state.scrollHeader}
                            basketNum={this.state.basketNum}
                            isLogin={this.state.user}
                            loginClick={() => this.setState({ modalLoginActive: true })}
                            profileClick={() => this.props.history.push("/profile")}
                        />
                    </section>
                    <UiCatalogFilter />
                    {/* page title */}
                    <section className="section classes-title">
                        <UiPageTitle
                            backImg={require("../../assets/images/header/bg-breacrum.jpg")}
                            pageLink="/subscribe"
                            pageName="Пользовательское соглашение"
                            title=""
                        />
                    </section>
                    {/* gifts catalog */}
                    <section className="section classes-catalog">
                        <div className="wrapper">
                            <div className="classes-catalog-wrap">
                             

                                <h3>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>

                                <p>1.1. Настоящее Пользовательское соглашение (далее &ndash; Соглашение) относится к сайту &laquo;<a href="http://Mateflowers.ru">Mateflowers.ru</a>&raquo; расположенному по адресу <a href="https://www.mateflowers.ru">https://www.mateflowers.ru</a>.</p>

                                <p>1.2. Сайт &laquo;<a href="http://Mateflowers.ru">Mateflowers.ru</a>&raquo; (далее &ndash; Сайт) является собственностью юридического лица <strong>ООО &ldquo;МАТЕ&rdquo;</strong> (ОГРН: 1207700250230, ИНН: 9719005540, адрес регистрации: Российская Федерация, 107023, Королев Г, ПЛ ЖУРАВЛЁВА, дом 10, стр 1, Э1П3К1-5)</p>

                                <p>1.3. Настоящее Соглашение регулирует отношения между Администрацией сайта &laquo;<a href="http://Mateflowers.ru">Mateflowers.ru</a>&raquo; (далее &ndash; Администрация сайта) и Пользователем данного Сайта.</p>

                                <p>1.4. Администрация сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.</p>

                                <p>1.5. Использование Сайта Пользователем означает принятие Соглашения и изменений, внесенных в настоящее Соглашение.</p>

                                <p>1.6. Пользователь несет персональную ответственность за проверку настоящего Соглашения на наличие изменений в нем.</p>

                                <h3>2. ОПРЕДЕЛЕНИЯ ТЕРМИНОВ</h3>

                                <p>2.1. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее значение:</p>

                                <p>2.1.1 &laquo;<a href="http://Mateflowers.ru">Mateflowers.ru</a>&raquo; &ndash; Интернет-ресурс, расположенный на доменном имени <a href="http://www.mateflowers.ru">www.mateflowers.ru</a>, осуществляющий свою деятельность посредством Интернет-ресурса и сопутствующих ему сервисов (далее - Сайт).</p>

                                <p>2.1.2. &laquo;<a href="http://Mateflowers.ru">Mateflowers.ru</a>&raquo; &ndash; сайт, содержащий информацию о Товарах и/или Услугах и/или Иных ценностях для пользователя, Продавце и/или Исполнителе услуг, позволяющий осуществить выбор, заказ и (или) приобретение Товара, и/или получение услуги.</p>

                                <p>2.1.3. Администрация сайта &ndash; уполномоченные сотрудники на управления Сайтом, действующие от имени юридического лица <strong>ООО &ldquo;МАТЕ&rdquo;</strong>.</p>

                                <p>2.1.4. Пользователь сайта (далее - Пользователь) &ndash; лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт.</p>

                                <p>2.1.5. Содержание сайта (далее &ndash; Содержание) - охраняемые результаты интеллектуальной деятельности, включая тексты литературных произведений, их названия, предисловия, аннотации, статьи, иллюстрации, обложки, музыкальные произведения с текстом или без текста, графические, текстовые, фотографические, производные, составные и иные произведения, пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение данного Содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на сайте <a href="http://www.mateflowers.ru">www.mateflowers.ru</a>.</p>

                                <h3>3. ПРЕДМЕТ СОГЛАШЕНИЯ</h3>

                                <p>3.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к содержащимся на Сайте Товарам и/или оказываемым услугам.</p>

                                <p>3.1.1. Сайт предоставляет Пользователю следующие виды услуг (сервисов):</p>

                                <ul>
                                    <li>ознакомление с товарами/услугами, размещенными на Сайте;</li>
                                    <li>выбор и заказ товаров/услуг для осуществления последующей покупки или оформления на данном Сайте.</li>
                                    <li>участие в программе лояльности</li>
                                </ul>

                                <p>3.1.2. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы).</p>

                                <p>3.2. Доступ к сайту предоставляется на бесплатной основе.</p>

                                <p>3.3. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению.</p>

                                <p>3.4. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.</p>

                                <h3>4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>

                                <p>4.1. Администрация сайта вправе:</p>

                                <p>4.1.1. Изменять правила пользования Сайтом, а также изменять содержание данного Сайта. Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте.</p>

                                <p><strong>4.2. Пользователь вправе:</strong></p>

                                <p>4.2.1. Пользоваться всеми имеющимися на Сайте услугами, а также приобретать любые Товары и/или Услуги, предлагаемые на Сайте.</p>

                                <p>4.2.2. Задавать любые вопросы, относящиеся к услугам сайта:</p>

                                <ul>
                                    <li>по телефону: +7 (495) 108-32-99</li>
                                    <li>по электронной почте: <a href="mailto:info@mateflowers.ru">info@mateflowers.ru</a></li>
                                    <li>через мессенджер Whatsapp: [по ссылке](<a href="https://api.whatsapp.com/send?phone=79956558637&amp;text=%D0%94%D0%BE%D0%B1%D1%80%D0%BE%D0%B3%D0%BE%20%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%B8%20%D1%81%D1%83%D1%82%D0%BE%D0%BA!%20%D0%A5%D0%BE%D1%87%D1%83%20%D1%83%D0%B7%D0%BD%D0%B0%D1%82%D1%8C%20%D0%BF%D0%BE%D0%B4%D1%80%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D1%82%D0%B8%20%D0%BF%D0%BE%20%D0%B7%D0%B0%D0%BA%D0%B0%D0%B7%D1%83%20%D0%B1%D1%83%D0%BA%D0%B5%D1%82%D0%B0.)">https://api.whatsapp.com/send?phone=79956558637&amp;text=Доброго времени суток! Хочу узнать подробности по заказу букета.)</a></li>
                                    <li>через любую удобную соц. сеть</li>
                                </ul>

                                <p>4.2.3. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и не запрещенных законодательством Российской Федерации.</p>

                                <p>4.2.5. Требовать от администрации скрытия любой информации о пользователе.</p>

                                <p><strong>4.3. Пользователь Сайта обязуется:</strong></p>

                                <p>4.3.1. Предоставлять по запросу Администрации сайта дополнительную информацию, которая имеет непосредственное отношение к предоставляемым услугам данного Сайта.</p>

                                <p>4.3.2. Соблюдать имущественные и неимущественные права авторов и иных правообладателей при использовании Сайта.</p>

                                <p>4.3.3. Не предпринимать действий, которые могут рассматриваться как нарушающие нормальную работу Сайта.</p>

                                <p>4.3.4. Не распространять с использованием Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации информацию о физических либо юридических лицах.</p>

                                <p>4.3.5. Избегать любых действий, в результате которых может быть нарушена конфиденциальность охраняемой законодательством Российской Федерации информации.</p>

                                <p>4.3.6. Не использовать Сайт для распространения информации рекламного характера, иначе как с согласия Администрации сайта.</p>

                                <p>4.3.7. Не использовать сервисы с целью:</p>

                                <p>4.3.7.1. нарушения прав несовершеннолетних лиц и (или) причинение им вреда в любой форме.</p>

                                <p>4.3.7.2. ущемления прав меньшинств.</p>

                                <p>4.3.7.3. представления себя за другого человека или представителя организации и (или) сообщества без достаточных на то прав, в том числе за сотрудников данного сайта.</p>

                                <p>4.3.7.4. введения в заблуждение относительно свойств и характеристик какого-либо Товара и/или услуги, размещенных на Сайте.</p>

                                <p>4.3.7.5. некорректного сравнения Товара и/или Услуги, а также формирования негативного отношения к лицам, (не) пользующимся определенными Товарами и/или услугами, или осуждения таких лиц.</p>

                                <p>4.3.7.6. загрузки контента, который является незаконным, нарушает любые права третьих лиц; пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти.</p>

                                <p>4.3.7.7. побуждения к совершению противоправных действий, а также содействия лицам, действия которых направлены на нарушение ограничений и запретов, действующих на территории Российской Федерации.</p>

                                <p>4.3.8. Обеспечить достоверность предоставляемой информации 4.3.9. Обеспечивать сохранность личных данных от доступа третьих лиц. <strong>4.4. Пользователю запрещается:</strong></p>

                                <p>4.4.1. Использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или отслеживания содержания Сайта.</p>

                                <p>4.4.2. Нарушать надлежащее функционирование Сайта.</p>

                                <p>4.4.3. Любым способом обходить навигационную структуру Сайта для получения или попытки получения любой информации, документов или материалов любыми средствами, которые специально не представлены сервисами данного Сайта.</p>

                                <p>4.4.4. Несанкционированный доступ к функциям Сайта, любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте.</p>

                                <p>4.4.4. Нарушать систему безопасности или аутентификации на Сайте или в любой сети, относящейся к Сайту.</p>

                                <p>4.4.5. Выполнять обратный поиск, отслеживать или пытаться отслеживать любую информацию о любом другом Пользователе Сайта.</p>

                                <p>4.4.6. Использовать Сайт и его Содержание в любых целях, запрещенных законодательством Российской Федерации, а также подстрекать к любой незаконной деятельности или другой деятельности, нарушающей права Сайта или других лиц.</p>

                                <h3>5. ИСПОЛЬЗОВАНИЕ САЙТА</h3>

                                <p>5.1. Сайт и Содержание, входящее в состав Сайта, принадлежит и управляется Администрацией сайта.</p>

                                <p>5.2. Содержание Сайта защищено авторским правом, законодательством о товарных знаках, а также другими правами, связанными с интеллектуальной собственностью, и законодательством о недобросовестной конкуренции.</p>

                                <p>5.3. Настоящее Соглашение распространяет свое действия на все дополнительные положения и условия о покупке Товара и/или оказанию услуг, предоставляемых на Сайте.</p>

                                <p>5.4. Информация, размещаемая на Сайте не должна истолковываться как изменение настоящего Соглашения.</p>

                                <p>5.5. Администрация сайта имеет право в любое время без уведомления Пользователя вносить изменения в перечень Товаров и услуг, предлагаемых на Сайте, и (или) их цен.</p>

                                <p>5.6. Документ указанный в пункте 5.7. настоящего Соглашения регулирует в соответствующей части и распространяют свое действие на использование Пользователем Сайта.</p>

                                <p>5.7. Политика конфиденциальности: <a href="https://matesale.ru/policy">ссылка на документ</a></p>

                                <p>5.8. Любой из документов, перечисленных в пункте 5.7 настоящего Соглашения может подлежать обновлению. Изменения вступают в силу с момента их опубликования на Сайте.</p>

                                <h3>6. ОТВЕТСТВЕННОСТЬ</h3>

                                <p>6.1. Любые убытки, которые Пользователь может понести в случае умышленного или неосторожного нарушения любого положения настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям другого Пользователя, Администрацией сайта не возмещаются.</p>

                                <p><strong>6.2. Администрация сайта не несет ответственности за:</strong></p>

                                <p>6.2.1. Задержки или сбои в процессе совершения операции, возникшие вследствие непреодолимой силы, а также любого случая неполадок в телекоммуникационных, компьютерных, электрических и иных смежных системах.</p>

                                <p>6.2.2. Действия систем переводов, банков, платежных систем и за задержки связанные с их работой.</p>

                                <p>6.2.3. Надлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых технических средств для его использования, а также не несет никаких обязательств по обеспечению пользователей такими средствами.</p>

                                <h3>7. НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ</h3>

                                <p>7.1. Администрация сайта имеет право раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации требует или разрешает такое раскрытие.</p>

                                <p>7.2. Администрация сайта вправе без предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту, если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия пользования Сайтом, а также в случае прекращения действия Сайта либо по причине технической неполадки или проблемы.</p>

                                <p>7.3. Администрация сайта не несет ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения или иного документа, содержащего условия пользования Сайтом.</p>

                                <h3>8. РАЗРЕШЕНИЕ СПОРОВ</h3>

                                <p>8.1. В случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения обязательным условием до обращения в суд является предъявление претензии (письменного предложения о добровольном урегулировании спора).</p>

                                <p>8.2. Получатель претензии в течение 30 календарных дней со дня ее получения, письменно уведомляет заявителя претензии о результатах рассмотрения претензии.</p>

                                <p>8.3. При невозможности разрешить спор в добровольном порядке любая из Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим законодательством Российской Федерации.</p>

                                <p>8.4. Любой иск в отношении условий использования Сайта должен быть предъявлен в течение 5 дней после возникновения оснований для иска, за исключением защиты авторских прав на охраняемые в соответствии с законодательством материалы Сайта. При нарушении условий данного пункта любой иск оставляется судом без рассмотрения.</p>

                                <h3>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</h3>

                                <p>9.1. Администрация сайта не принимает встречные предложения от Пользователя относительно изменений настоящего Пользовательского соглашения.</p>

                                <p>9.2. Отзывы Пользователя, размещенные на Сайте, не являются конфиденциальной информацией и могут быть использованы Администрацией сайта без ограничений.</p>

                            </div>
                        </div>
                    </section>
                    {/* gifts footer */}
                    <section className="section classes-footer">
                        <UiFooter />
                    </section>
                </div>

                <UiBtnTop showTopBtn={this.state.showTopBtn} />

                <UiBtnBasket
                    cartNum={this.state.basketNum}
                    infoActive={this.state.infoActive}
                    callBack={() => this.setState({ infoActive: false })}
                />

                <UiModalLogin
                    modalOpen={this.state.modalLoginActive}
                    modalClose={() => this.setState({ modalLoginActive: false })}
                    callBack={() => {
                        this.setState({ modalLoginActive: false });
                        this.props.history.push("/profile");
                    }}
                />

            </div>
        );
    }
}

export default AgreementPage;
