import {getEnvOfStorage} from "./EnvContext";


export const getCityData = () => {
  try {
    return fetch('http://ip-api.com/json/', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }, 
    }).then(function(response) {
      return response.json();
    });
  } catch (err) {
    console.log(err)
    return null;
  }

}

 
export const loginUser = (_login, _pass) => {
  return fetch(getEnvOfStorage()?.apiUrl+'/users/login', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: _login,
        code: _pass,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const registrationUser = (_phone, _token = null) => {
  return fetch(getEnvOfStorage()?.apiUrl+'/users/register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: _phone,
        api_token: _token,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const getUser = (_token) => {
  return fetch(getEnvOfStorage()?.apiUrl+'/users/profile', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _token,
      })
    }).then(function(response) {
      return response.json();
    });
}


 
export const setUserMetrics = (_phone, _code) => {
  return fetch(`${getEnvOfStorage()?.apiUrl}/users/set/metric/${_phone}/${_code}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    }).then(function(response) {
      return response.json();
    });
}
 
 
export const updateUser = (_token, _id, _mail, _address, _name) => {
  return fetch(getEnvOfStorage()?.apiUrl+'/users/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _token,
        id: _id,
        email:  _mail,
        address: _address,
        name: _name
      })
    }).then(function(response) {
      return response.json();
    });
}
 
export const createOrder = ( _data) => {
  return fetch(getEnvOfStorage()?.apiUrl+'/deals/add/offline', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data)
    }).then(function(response) {
      return response.json();
    });
}

export const createOrderOnline = ( _data) => {
  return fetch(getEnvOfStorage()?.apiUrl+'/deals/add/online', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_data)
    }).then(function(response) {
      return response.json();
    });
}

export const getOrders = (_token, _id) => {
  return fetch(getEnvOfStorage()?.apiUrl + '/users/deals', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          id: _id,
      })
  }).then(function (response) {
      return response.json();
  });
}

 
export const updateDelivery = (_token, _id, _delivery) => {
  return fetch(getEnvOfStorage()?.apiUrl + '/users/order/add/delivery', {
      method: 'POST',
      headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
          api_token: _token,
          order_id: _id,
          deliverys: _delivery
      })
  }).then(function (response) {
      return response.json();
  });
}
 

