import React, { useState, useEffect, useRef } from 'react';

import { ReactComponent as SvgArrow } from "../../assets/images/places/arrow.svg";
import { ReactComponent as SvgMoscow } from "../../assets/images/places/moskva.svg";
import { ReactComponent as SvgPlace } from "../../assets/images/places/pin.svg";
import { ReactComponent as SvgSpb } from "../../assets/images/places/piter.svg";
import { ReactComponent as SvgKorolev } from "../../assets/images/places/korolev.svg";

import { getCityData } from "../../services/Users";

import './UiPlaceSelect.scss';
import { storeData, retrieveData } from '../../services/Storage';

import StorageConst from "../../constants/Storages"; 

const UiIcon = (props) => {
    return (
        <div className="place-select-item-icon">
            {props.icon === "moscow" && <SvgMoscow />}
            {props.icon === "korolev" && <SvgKorolev />}
        </div>
    );
};

const UiPlaceSelect = (props) => {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);
 
    const [selectOpen, setSelectOpen] = useState(false);
    const [active, setActive] = useState(0);
    const placeList = [
        { icon: "moscow", option: "Москва и область", text1: "Москве", text2: "Москва" },
        { icon: "korolev", option: "Королев", text1: "Королев", text2: "Королев" }
    ];

    useEffect(() => { 
        if(window.location.href.includes("korolev")) setActive(1); else setActive(0); 
        getCityData().then((data) => {
           if(data.city == "Korolev"){
                setActive(1);
                /*
                if(!window.location.href.includes("spb") && retrieveData(StorageConst.STORE_LOCATION) == null ){
                    window.location.href = "https://spb.mateflowers.ru/";
                }
                */
           } 
        })
    }, [])

    function useOutsideAlerter(ref) {
        useEffect(() => {

            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setSelectOpen(false);

                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }



    var list = placeList.map((item, index) => {
        return (
            <button
                key={index}
                className={"place-select-item" + (active === index ? " active" : "")}
                onClick={() => { 
                    setActive(index);
                    storeData(StorageConst.STORE_LOCATION, index);
                    if(index == 1) window.location.href = "https://korolev.mateflowers.ru/"; else window.location.href = "https://mateflowers.ru/"; 
                }}
            >
                <UiIcon icon={item.icon} />
                <span>{item.option}</span>
            </button>
        );
    })

    return (
        <div className="place-select-wrap">
            <SvgPlace className="place-select-icon" />
            <div className="place-select">
                <span>Доставка по&nbsp;</span>
                <div className="place-select-button" onClick={() => setSelectOpen(!selectOpen)}>
                    <span className="place-select-button-text1">{placeList[active].text1}</span>
                    <span className="place-select-button-text2">{placeList[active].text2}</span>
                    <div ref={wrapperRef} className={"place-select-list" + (selectOpen ? " active" : "")}>
                        {list}
                    </div>
                    <SvgArrow className="place-select-button-icon" />
                </div>
            </div>
        </div>
    );
}
export default UiPlaceSelect;

