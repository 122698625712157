import React, { Component } from "react";

import "./UiTextInput.scss";

class UiTextInputSV extends Component {
  state = {
    geocoder: null,
    router: null,
    i: 0,
    mkad_points: [
      [37.842663, 55.774543],
      [37.842663, 55.774543],
      [37.84269, 55.765129],
      [37.84216, 55.75589],
      [37.842232, 55.747672],
      [37.841109, 55.739098],
      [37.840112, 55.730517],
      [37.839555, 55.721782],
      [37.836968, 55.712173],
      [37.832449, 55.702566],
      [37.829557, 55.694271],
      [37.831425, 55.685214],
      [37.834695, 55.676732],
      [37.837543, 55.66763],
      [37.839295, 55.658535],
      [37.834713, 55.650881],
      [37.824948, 55.643749],
      [37.813746, 55.636433],
      [37.803083, 55.629521],
      [37.793022, 55.623666],
      [37.781614, 55.617657],
      [37.769945, 55.61114],
      [37.758428, 55.604819],
      [37.747199, 55.599077],
      [37.736949, 55.594763],
      [37.721013, 55.588135],
      [37.709416, 55.58407],
      [37.695708, 55.578971],
      [37.682709, 55.574157],
      [37.668471, 55.57209],
      [37.649948, 55.572767],
      [37.63252, 55.573749],
      [37.619243, 55.574579],
      [37.600828, 55.575648],
      [37.586814, 55.577785],
      [37.571866, 55.581383],
      [37.55761, 55.584782],
      [37.534541, 55.590027],
      [37.527732, 55.59166],
      [37.512227, 55.596173],
      [37.501959, 55.602902],
      [37.493874, 55.609685],
      [37.485682, 55.616259],
      [37.477812, 55.623066],
      [37.466709, 55.63252],
      [37.459074, 55.639568],
      [37.450135, 55.646802],
      [37.441691, 55.65434],
      [37.433292, 55.66177],
      [37.425513, 55.671509],
      [37.418497, 55.680179],
      [37.414338, 55.687995],
      [37.408076, 55.695418],
      [37.397934, 55.70247],
      [37.388978, 55.709784],
      [37.38322, 55.718354],
      [37.379681, 55.725427],
      [37.37483, 55.734978],
      [37.370131, 55.745291],
      [37.369368, 55.754978],
      [37.369062, 55.763022],
      [37.369691, 55.771408],
      [37.370086, 55.782309],
      [37.372979, 55.789537],
      [37.37862, 55.796031],
      [37.387047, 55.806252],
      [37.390523, 55.81471],
      [37.393371, 55.824147],
      [37.395176, 55.832257],
      [37.394476, 55.840831],
      [37.392949, 55.850767],
      [37.397368, 55.858756],
      [37.404564, 55.866238],
      [37.417446, 55.872996],
      [37.429672, 55.876839],
      [37.443129, 55.88101],
      [37.45955, 55.882904],
      [37.474237, 55.88513],
      [37.489634, 55.889361],
      [37.503001, 55.894737],
      [37.519072, 55.901823],
      [37.529367, 55.905654],
      [37.543749, 55.907682],
      [37.559757, 55.909418],
      [37.575423, 55.910881],
      [37.590488, 55.90913],
      [37.607035, 55.904902],
      [37.621911, 55.901152],
      [37.633014, 55.898735],
      [37.652993, 55.896458],
      [37.664905, 55.895661],
      [37.681443, 55.895106],
      [37.697513, 55.894046],
      [37.711276, 55.889997],
      [37.723681, 55.883636],
      [37.736168, 55.877359],
      [37.74437, 55.872743],
      [37.75718, 55.866137],
      [37.773646, 55.8577],
      [37.780284, 55.854234],
      [37.792322, 55.848038],
      [37.807961, 55.840007],
      [37.816127, 55.835816],
      [37.829665, 55.828718],
      [37.836914, 55.821325],
      [37.83942, 55.811538],
      [37.840166, 55.802472],
      [37.841145, 55.793925],
    ],
    minShir: 37.842663,
    minDolg: 55.774543,
    minDelta: 10000,
    minJ: 0,
    delta: 100000,
    ymapsAPIerror: true,

    suggestValue: "",
  };

  constructor(props) {
    super(props);

    window.ymaps.ready(() => {
      //console.log("ready");

      this.initMap();
      this.suggestView = new window.ymaps.SuggestView("suggest", {
        provider: {
          suggest: function (request, options) {
            return window.ymaps.suggest("Москва , " + request);
          },
        },
      });

      this.suggestView.events.add("select", (e) => {
        //console.log(e.originalEvent.item.value, "select");
        this.setState({ suggestValue: addr })
        var addr = e.originalEvent.item.value;
        this.getMKADDistance(addr);
      });

      document.getElementById("suggest").onblur = () => {
        //console.log("document.getElementById ", document.getElementById("suggest").value );
        setTimeout(() => {
          if (
            this.state.suggestValue == "" ||
            document.getElementById("suggest").value != this.state.suggestValue) this.getMKADDistance(document.getElementById("suggest").value);
        }, 1000)

      }

    });
  }

  componentDidMount() {
    if (this.props.value && this.props.value != "") {
      document.getElementById("suggest").value = this.props.value;
    }
  }

  componentDidUpdate(nProps, nState) {
    if (nProps.value != this.props.value) {
      this.getMKADDistance( this.props.value );
    }
  }


  initMap() {
    this.map = new window.ymaps.Map("ymap", {
      center: [55.914060, 37.841469], // куда ставить центр карты по умолчанию
      zoom: 13, // зум
    }); // Создание экземпляра карты и его привязка к созданному контейнеру
    this.state.ymapsAPIerror = false; // с API все в порядке
  }

  /*
   * Функция, проверяющая, находится ли указанная точка внутри МКАД
   * @param x широта
   * @param y долгота
   */
  inPoly(x, y) {
    var j = this.state.mkad_points.length - 1,
      c = false; // true/false - внутри или вне полигона
    for (let i = 0; i < this.state.mkad_points.length; i++) {
      if (
        ((this.state.mkad_points[i][1] <= y &&
          y < this.state.mkad_points[j][1]) ||
          (this.state.mkad_points[j][1] <= y &&
            y < this.state.mkad_points[i][1])) &&
        x >
        ((this.state.mkad_points[j][0] - this.state.mkad_points[i][0]) *
          (y - this.state.mkad_points[i][1])) /
        (this.state.mkad_points[j][1] - this.state.mkad_points[i][1]) +
        this.state.mkad_points[i][0]
      ) {
        c = !c;
      }
      j = i;
    }
    return c;
  }

  /*
   * Делаем некоторые замены в адресе, чтобы не было неточностей результата
   */
  refineAddress(address) {
    address = address.toUpperCase();

    address = address.replace("ПРОСПЕКТ", "");
    address = address.replace("ПРКТ", "");
    address = address.replace("ПР-КТ", "");
    address = address.replace("ПР-Т", "");
    address = address.replace("ПРС-КТ", "");
    address = address.replace("ПРС-Т", "");
    address = address.replace("П-КТ", "");
    address = address.replace("П-Т", "");

    return address;
  }

  getMKADDistance(_addr) {
    this.props.callBack({ address: _addr, distance: 9999 });
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>

        <input
          id="suggest"
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          type={this.props.type} 
          className={"text-input" + (this.props.btnText ? " text-right" : "") + (this.props.overflow ? " overflow" : "")}
        />
        {this.props.btnText ? (
          <button className="btn" onClick={this.props.onClick}>
            {this.props.btnText}
          </button>
        ) : null}
      </div>
    );
  }
}
export default UiTextInputSV;
