import React, { Component } from "react";

import "./Home.scss";

import UiBtnBasket from "../../components/buttons/UiBtnBasket";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiCatalog from "../../components/catalog/UiCatalog";
import UiCatalogFilter from "../../components/catalog/UiCatalogFilter";
import UiCatalogSlider from "../../components/catalog/UiCatalogSlider";
import UiHeader from "../../components/header/UiHeader";
import UiFreeGiftCard from "../../components/cards/UiFreeGiftCard";
import UiFooter from "../../components/footer/UiFooter";
import UiLoader from "../../components/modals/UiLoader";
import UiModalCatalog from "../../components/modals/UiModalCatalog";
import UiModalLogin from "../../components/modals/UiModalLogin";

import { Helmet } from "react-helmet";

import { loadUser } from "../../services/Auth";
import { storeData, retrieveData } from "../../services/Storage";
import { getSettings } from "../../services/Settings";
import { GetFlowersByCategory, getTags } from "../../services/Flowers";

import Categories from "../../constants/Categories"

class HomePage extends Component {
  state = {
    loader: true,
    showTopBtn: false,
    infoActive: false,
    scrollHeader: false,
    basketNum: 0,
    activeCatalogCategory: 0,

    user: null,

    settings: {},
    selectedItem: {
      title: null,
      description: null,
      composition: null,
      price: null,
      modifications: null,
    },
    cart: [],
    mainList: [],
    giftsList: [],
    waifuList: [],
    manList: [],
    tags: [],
    sliderImages: [
      require("../../assets/images/demo/g_1611874578_aee0ebc9ca5b4e5a955a8205a5800f78.jpeg"),
      require("../../assets/images/demo/g_1612148202_c0de41e573e448418c975725fa4dfca8.jpeg"),
    ],

    modalCatalogActive: false,
    modalLoginActive: false,
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._load();

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
      if (this.state.topOffset > 170) {
        this.setState({ scrollHeader: true });
      } else {
        this.setState({ scrollHeader: false });
      }
      if (this.state.topOffset > 500) {
        this.setState({ showTopBtn: true });
      } else {
        this.setState({ showTopBtn: false });
      }
    });
  }

  _load() {
    let _cart = retrieveData("cart");
    let _user = loadUser();
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: this._getBasketNum(_cart) });
    }
    if (_user) {
      this.setState({ user: _user });
    }
    getSettings().then((res) => {
      if (res.response) {
        this.setState({ settings: res.response });
        storeData("settings", res.response);
      }
    });

    getTags().then((res) => {
      if (res.response) {
        this.setState({ tags: res.response })
      }
    })
    GetFlowersByCategory(0).then((res) => {
      this.setState({ mainList: res.response.sort((a, b) => a.sort - b.sort), loader: false }, () =>
        this._openFlower(res.response.sort((a, b) => a.sort - b.sort), this.props.match.params.flower)
      );
    });
    GetFlowersByCategory(1).then((res) => {
      this.setState({ waifuList: res.response.sort((a, b) => a.sort - b.sort) });
    });
    GetFlowersByCategory(2).then((res) => {
      this.setState({ manList: res.response.sort((a, b) => a.sort - b.sort) });
    });
    GetFlowersByCategory(3).then((res) => {
      this.setState({ giftsList: res.response.sort((a, b) => a.sort - b.sort) });
    });

  }

  _openFlower(arr, id = null) {
    if (id) {
      arr.map((item) => {
        if (item.id == id || item.title_url == id) {
          this.setState({
            modalCatalogActive: true,
            selectedItem: item,
          });
        }
      });
    }
  }

  hasInCart(id) {
    let fl = false;
    this.state.cart.map((item) => {
      if (id == item.id) fl = true;
    });
    return fl;
  }

  _getBasketNum(_cart) {
    let num = 0;
    _cart.map((item) => {
      num = num + item.count;
    });
    return num;
  }

  _toCart(item) {
    let arr = this.state.cart;
    let _item = item;
    if (_item.category == 1) _item.price = 0;
    _item.count = 1;
    _item.color = 0;
    _item.giftbox = false;
    if (this.hasInCart(_item.id)) {
      let index = 0;
      arr.map((arrItem, arrIndex) => {
        if (arrItem.id == item.id) index = arrIndex;
      });
      arr[index].count = arr[index].count + 1;
    } else {
      arr = arr.concat(_item);
    }
    return arr;
  }

  _getTagsValue(_id) {
    let _item = {
      is_del: 0,
      title: Categories.TAGS[_id],
      max: 20
    }; 
    this.state.tags.map((item) => {
      if (item.type_id == _id) {
        _item = item;
      }
    })
    return _item;
  }


  addToCart(_cart) {
    this.setState({
      basketNum: this._getBasketNum(_cart),
      cart: _cart,
      infoActive: true,
    });
    storeData("cart", _cart);
  }

  render() {
    let carouselList = this.state.tags.map((item, index) => {
 
      if (index > 0 && item.is_del == 0) {
        return (
          <section className="section home-catalog" key={index}>
            <div className="wrapper">
              <UiCatalogSlider
                categoryName={item.title}
                type={item.type_id}
                max={item.max}
                slidesList={this.state.mainList}
                shopClick={(_item) => {
                  this.addToCart(this._toCart(_item));
                }}
                openClick={(_item) => {
                  this.props.history.push(`/flowers/${_item.title_url}`);
                  this._openFlower(this.state.mainList, _item.title_url);
                }}
              />
            </div>
          </section>
        )
      }

    })
    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>
            Доставка букетов за 120 минут от 1650 руб. в Королёве - MATE
            flowers
          </title>
          <meta
            name="description"
            content="Всегда свежие цветы. Собираем букеты каждое утро. Доставляем самыми вежливыми курьерами!"
          />
          <meta
            name="keywords"
            content="Доставка букетов Королев, купить цветы Королев"
          />
        </Helmet>
        <div id="wrapper" className="page-wrapper">
          {/* home header */}
          <section className="section home-header">
            <UiHeader
              page="home"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              manList={this.state.manList}
              profileClick={() => this.props.history.push("/profile")}
            />
            <div className="wrapper home-info-slider">
              {/*<UiInfoSlider images={this.state.sliderImages} />*/}
            </div>
          </section>
          <UiCatalogFilter page={"bouquet"} />
          {/* home catalog */}
         

          {/* new slider */}
          { carouselList  }
      
          <section className="section home-catalog">
            <div className="wrapper">
              <UiCatalog
                page={"bouquet"} 
                maxElementCount={
                  window.innerWidth > 768 ? 12 : window.innerWidth > 640 ? 4 : 2
                }
                mainList={this.state.mainList}
                shopClick={(item) => {
                  this.addToCart(this._toCart(item));
                }}
                openClick={(item) => {
                  this.props.history.push(`/flowers/${item.title_url}`);
                  this._openFlower(this.state.mainList, item.title_url);
                }}
              />
            </div>
          </section>
          

          {/* home gifts */}
          <section className="section home-gifts">
            <h2>Подарки к каждому букету</h2>
            <div className="wrapper">
              <div className="gifts-wrap">
                <UiFreeGiftCard
                  image={require("../../assets/images/gifts/free-1.jpg")}
                  title="Бесплатная открытка"
                />
                <UiFreeGiftCard
                  image={require("../../assets/images/gifts/free-4.jpg")}
                  title="Подкормка для цветов"
                />
                <UiFreeGiftCard
                  image={require("../../assets/images/gifts/free-2.jpg")}
                  title="Инструкция к букету"
                />
                <UiFreeGiftCard
                  image={require("../../assets/images/gifts/free-3.jpg")}
                  title="Упаковка и аквабокс"
                />
              </div>
            </div>
          </section>
          {/* home footer */}
          <section className="section home-footer">
            <UiFooter />
          </section>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiBtnBasket
          cartNum={this.state.basketNum}
          infoActive={this.state.infoActive}
          callBack={() => this.setState({ infoActive: false })}
        />

        <UiLoader modalOpen={this.state.loader} />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.props.history.push("/profile");
          }}
        />

        <UiModalCatalog
          modalOpen={this.state.modalCatalogActive}
          modalClose={() => {
            this.props.history.push(`/`);
            this.setState({ modalCatalogActive: false });
          }}
          imageList={this.state.selectedItem.imgs}
          item={this.state.selectedItem}
          title={this.state.selectedItem.title}
          filter={this.state.selectedItem.type}
          description={this.state.selectedItem.description}
          composition={this.state.selectedItem.composition}
          giftsList={this.state.giftsList}
          colors={
            this.state.selectedItem.modifications != null
              ? this.state.selectedItem.modifications
                .split(",")
                .filter((elem, index, self) => {
                  return index === self.indexOf(elem);
                })
              : []
          }
          price={this.state.selectedItem.price}
          cart={this.state.cart}
          settings={this.state.settings}
          onClick={(cart) => {
            this.addToCart(this._toCart(cart));
            this.setState({ modalCatalogActive: false });
          }}
        />
      </div>
    );
  }
}

export default HomePage;
