import React, { Component } from 'react';

import './UiFooter.scss';

import appStoreLogo from '../../assets/images/footer/app-store-badge-ru.svg';
import googlePlayLogo from '../../assets/images/footer/google-play-badge-ru.svg';
import iconPin from '../../assets/images/footer/icon-pin.svg';
import iconPhone from '../../assets/images/footer/icon-phone.svg';
import iconWhatsapp from '../../assets/images/footer/icon-whatsapp.svg';

import UiNotify from "../notify/UiNotify";

import { getInstagramPhoto } from '../../services/Settings';
import { retrieveData } from "../../services/Storage";

class UiFooter extends Component {

  state = {
    instaImages: [
      {img: "https://i.imgur.com/NZeysjO.jpg", url:  "https://www.instagram.com/mateflowers/" },
      {img: "https://i.imgur.com/8wgTgkF.jpg", url:  "https://www.instagram.com/mateflowers/" },
      {img: "https://i.imgur.com/c2hofCp.jpg", url:  "https://www.instagram.com/mateflowers/" },
      {img: "https://i.imgur.com/hGU7gmi.jpg", url:  "https://www.instagram.com/mateflowers/" },
    ],
    settings: {
      alert_active: 0,
      alert_title: "",
      alert_btn: "",
    }
  }

  constructor(props) {
    super(props);

 
  }

  componentDidMount(){
    let _settings = retrieveData("settings");
    if(_settings) this.setState({settings: _settings }) 
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    var instaList = this.state.instaImages.map((item, index) => {
      if (index < 8) {
        return (
          <div className="inst-card" key={index} style={{ backgroundImage: "url(" + item.img + ")" }} onClick={() => window.open(item.url, "_parent")}></div>
        )
      }
    });

    return (
      <footer className="footer">
        <div className="wrapper footer-inner">
          <div className="footer-col">
            <h3>MATE flowers</h3>
            <p>Доставка букетов от 1650 руб. в Королевe за 120 минут</p>
            <div className="footer-contact-item">
              <img src={iconPin} />
              <span>Королев, Калининградская ул., 12</span>
            </div>
            <div className="footer-contact-item">
              <img src={iconPhone} />
              <span>+7 495 108-32-99</span>
            </div>
           
          </div>
          <div className="footer-col">
            <h3>Информация</h3>
            <div className="footer-link-item">
              <a href="/about">О MATE flowers</a>
            </div>
            <div className="footer-link-item">
              <a href="/policy">Политика конфиденциальности</a>
            </div>
            <div className="footer-link-item">
              <a href="/agreement">Пользовательское соглашение</a>
            </div>
            
            <div className="footer-link-item">
              <a href="/loyalty">Программа лояльности</a>
            </div>
            
            <div className="footer-link-item">
              <a href="/delivery">Доставка</a>
            </div>
             <div className="footer-link-item">
              <a href="/back">Возврат</a>
            </div>
            <div className="footer-link-item">
              <a href="/contacts">Контакты</a>
            </div>
            
          </div>
          <div className="footer-col">
            <h3>Услуги</h3>
            <div className="footer-link-item">
              <a href="/gifts">Подарки к букетам</a>
            </div>
          
            
          </div>
          <div className="footer-col">
            <h3>Instagram</h3>
            <div className="instagram-wrap">
              {instaList}
            </div>
          </div>
        </div>
        <div className="sub-footer">
          <div className="wrapper sub-footer-wrap">
            <p>Copyright © 2020 - All rights reserved. Сайт разработан <a href="http://devkot.ru/">DEVKOT</a></p>
            <div className="sub-footer-apps">
              <a href="https://apps.apple.com/ru/app/mate-flowers-gifts/id1567569850">
                <img src={appStoreLogo} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=ru.mateflowers">
                <img src={googlePlayLogo} />
              </a>
            </div>
          </div>
        </div>
       
        {this.state.settings.alert_active == 1 ?  <UiNotify title={this.state.settings.alert_title} btn={this.state.settings.alert_btn} /> : null}
      </footer>
    );
  }
}
export default UiFooter;